import React from 'react';

import { Stack } from '@mui/material';
import { SettingsBackupRestoreRounded } from '@mui/icons-material';

import { StyledButton, StyledText } from '@/components/lib';
import { StyledContainer, StyledRowInnerContainer, StyledInnerContainer } from './SamplesAnnotationEst.styles';

import { AnnotationSchema } from '@/helpers/services/api';
import { getAnnotation } from '@/components/Samples/SamplesTable/SamplesTable.helpers';

import { constants } from '../../samplesAnnotationCell.constants';

interface SamplesAnnotationEstProps {
  estAnnotation?: AnnotationSchema | null;
  handleCloseRootMenu: (e: { stopPropagation?: () => void }, isRevert?: boolean) => void;
  handleModifyAnnotationId: (annotation: string | null, isRevert?: boolean) => void;
  annotationVal: string | undefined;
}

const { deleteButtonLabel, estAnnotation } = constants;
const { title, buttonLabel, noReason } = estAnnotation;

export const SamplesAnnotationEst = (props: SamplesAnnotationEstProps) => {
  const { estAnnotation, handleCloseRootMenu, handleModifyAnnotationId, annotationVal } = props;

  if (!annotationVal && !estAnnotation?.value) {
    return <></>;
  }

  const handleRevertClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    estAnnotation?.value ? handleModifyAnnotationId(estAnnotation.value, true) : handleModifyAnnotationId(null, true);
    handleCloseRootMenu && handleCloseRootMenu(event, true);
  };

  return (
    <StyledContainer>
      {estAnnotation?.value && <StyledText text={title} type="small" />}
      <StyledInnerContainer is_margin_top={!estAnnotation?.value ? 'true' : 'false'}>
        {estAnnotation?.value && (
          <StyledRowInnerContainer>
            <Stack>{getAnnotation(estAnnotation?.value, estAnnotation?.is_estimated)}</Stack>
            <StyledText text={estAnnotation?.reason || noReason} type="tinyBold" />
          </StyledRowInnerContainer>
        )}
        <StyledButton
          variant="text"
          label={estAnnotation?.value ? buttonLabel : deleteButtonLabel}
          onClick={e => handleRevertClick(e)}
          startIcon={<SettingsBackupRestoreRounded />}
        />
      </StyledInnerContainer>
    </StyledContainer>
  );
};
