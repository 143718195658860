import { Stack, styled } from '@mui/material';

import { StyledContainer as DSStyledContainer, StyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

export const StyledDiffContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'diff'
})<{ diff?: boolean; isDarkMode?: string }>(({ theme, diff, isDarkMode }) => ({
  overflowY: 'auto',
  color: theme.palette.grey[700],
  animation: `${fadeInAnimation} 0.5s ease`,

  '.css-vl0irh-content': { background: `${theme?.palette?.grey[200]} !important` },
  '.css-cncyp1-word-diff': { padding: '0 2px' },
  '.css-1u4zuq6-word-added': {
    fontWeight: 600,
    background: isDarkMode === 'true' ? 'rgba(1, 110, 153, 1)' : 'rgba(163, 229, 255, 1)'
  },
  '.css-hf3w1f-word-removed': {
    fontWeight: 600,
    background: isDarkMode === 'true' ? 'rgba(92, 54, 164, 1)' : 'rgba(218, 201, 249, 1)'
  },
  '.css-cnnxkz-diff-added': { display: diff ? 'auto' : 'none', background: `${theme?.palette?.grey[200]} !important` },
  '.css-rq9a2a-diff-removed': {
    display: diff ? 'none' : 'auto',
    background: `${theme?.palette?.grey[200]} !important`
  },
  // '.css-1ks9aww-gutter, .css-17vezug-marker': { display: 'none' },
  '.css-1klnsbn-empty-gutter, .css-17vezug-marker': { display: 'none' },
  '.MuiTypography-body2': { fontWeight: 800, color: theme.palette.grey[700] },
  '.MuiTypography-body1': { fontWeight: 500, color: theme.palette.grey[600], fontSize: '12px' },
  '.css-1ks9aww-gutter': {
    minWidth: '24px',
    background: `${theme?.palette?.grey[200]} !important`
  },
  '.css-h84nzi-line-number': {
    fontWeight: 800,
    color: `${diff ? 'rgba(54, 123, 244, 1)' : 'rgba(145, 89, 247, 1)'} !important`
  },

  table: { background: `${theme?.palette?.grey[200]} !important` },
  pre: { fontSize: '12px', color: `${theme.palette.grey[600]} !important`, fontFamily: 'Manrope' }
}));

export const StyledContentBottomContainer = styled(StyledContainer)(({ theme }) => ({
  borderRadius: '12px',
  color: theme.palette.grey[600],
  background: theme?.palette?.grey[200],
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  borderTopRightRadius: '0',
  borderTopLeftRadius: '0',
  marginTop: '-8px',
  gap: 0,
  padding: '0 24px 16px'
}));

export const StyledAnnotationContentContainer = styled(StyledContainer)<{ is_sticky?: string; is_full_width?: string }>(
  ({ is_sticky, is_full_width, theme }) => ({
    alignItems: 'center',
    flexDirection: 'row',
    gap: '4px',
    marginLeft: 'auto',
    background: is_sticky === 'true' ? theme?.palette?.grey[200] : 'transparent',
    padding: is_sticky === 'true' ? '4px' : 0,
    marginTop: is_sticky === 'true' ? 'auto' : 0,
    position: is_sticky === 'true' ? 'sticky' : 'static',
    bottom: 0,
    width: is_full_width === 'true' ? '100%' : 'auto',
    borderRadius: is_full_width === 'true' ? '0' : '12px'
  })
);

export const StyledPropertyScoreContainer = styled(StyledContainer)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '12px',
  width: 'auto',
  padding: 0
});

export const StyledTogglerContainer = styled(Stack)(({ theme }) => ({
  padding: '2px',
  borderRadius: '12px',
  alignContent: 'center',
  background: theme.palette.grey[200],
  marginRight: 'auto',

  '.MuiFormControlLabel-labelPlacementEnd': {
    marginLeft: '0'
  }
}));

export const StyledPropertyScoreNumberContainer = styled(StyledContainer)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
  gap: 0,
  padding: '0 8px',
  border: `2px solid ${theme.palette.grey[300]}`,
  borderRadius: '8px',
  width: '50px'
}));

export const StyledPropColorText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[600]
}));

export const StyledInfoRetrievalText = styled(StyledText)<{ is_margin?: string }>(({ theme, is_margin }) => ({
  color: theme.palette.grey[600],
  marginBottom: is_margin === 'true' ? '8px' : 0,
  overflow: 'visible'
}));

export const StyledContentContainer = styled(DSStyledContainer)<{ is_sticky?: string }>(({ theme, is_sticky }) => ({
  overflowY: 'auto',
  fontSize: '12px',
  borderRadius: '12px',
  whiteSpace: 'pre-line',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  color: theme.palette.grey[600],
  background: theme?.palette?.grey[200],
  animation: `${fadeInAnimation} 0.5s ease`,
  height: is_sticky === 'true' ? '318px' : '270px',
  paddingBottom: is_sticky === 'true' ? 0 : '12px',

  '&::-webkit-scrollbar': {
    width: '7px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '8px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.grey[500]
  }
}));

export const StyledSimilarityContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '0',
  padding: '0',
  display: 'grid',
  marginLeft: 'auto',
  alignItems: 'center',
  width: 'fit-content',
  gridTemplateColumns: '98px 44px',
  animation: `${fadeInAnimation} 0.5s ease-in-out`,

  '.MuiTypography-h5': { fontWeight: 500 },
  '.MuiTypography-body2': {
    padding: '2px 4px',
    textAlign: 'center',
    borderRadius: '6px',
    color: theme.palette.grey[600],
    background: theme.palette.grey[200]
  },

  '@media (min-width:1440px)': {
    gridTemplateColumns: '110px 44px'
  }
}));

export const StyledContentHeader = styled(Stack)({
  gap: '24px',
  height: '30px',
  padding: '0 6px',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between'
});
