import { Stack, styled } from '@mui/material';

import { StyledContainer as DSStyledContainer } from '@/components/lib';

export const StyledContainer = styled(Stack)({
  gap: '8px',

  '.MuiTypography-h5': {
    margin: '16px auto 8px'
  }
});

export const StyledInnerContainer = styled(DSStyledContainer)<{ is_margin_top?: string }>(
  ({ theme, is_margin_top }) => ({
    padding: '16px',
    background: theme.palette.grey[100],
    marginTop: is_margin_top === 'true' ? '16px' : '0',

    '.MuiButton-text': {
      padding: '0',
      fontSize: '12px',
      color: theme.palette.primary.main
    }
  })
);

export const StyledRowInnerContainer = styled(StyledContainer)({
  gap: '8px',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '36px auto'
});
