export const constants = {
  similarityLinkLabel: 'Similar Response',
  menu: { options: ['good', 'bad', 'unknown'], inputPlaceHolder: 'Type Reason...' },
  estAnnotation: {
    title: 'Estimated Annotation',
    buttonLabel: 'Revert back to estimated',
    noReason: 'No reason provided'
  },
  deleteButtonLabel: 'Delete Annotation'
};
